import React from 'react';
import { useTranslation } from 'react-i18next';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FieldError } from 'react-hook-form';
import calendarIcon from '../../assets/images/calendar.png';
import './DateInput.scss';

interface Props extends ReactDatePickerProps {
  className?: string;
  label?: string;
  error?: FieldError;
  showCalendarIcon?: boolean;
}

export default function DateInput(props: Props) {
  const { className, label, error, showCalendarIcon = true } = props;
  const { t } = useTranslation();

  const handleDateChangeRaw = (e: any) => {
    e.preventDefault();
  };

  const getError = () => {
    if (!error) {
      return null
    }

    const msg = error.message as unknown as { key: string; extra: any };

    if (msg.key) {
      return t(`errors:form.${msg.key}`, {
        field: props.name ? t(`formFields:${props.name}`) : null,
        extra: msg.extra,
      });
    }

    return error.message;
  };

  return (
    <div className="date-input">
      {label && <span className="title-text">{label}</span>}
      <div style={{ position: 'relative' }}>
        <DatePicker
          {...props}
          className={`date-picker ${className}`}
          placeholderText={
            props.placeholderText ? props.placeholderText : t('selectDate', { defaultValue: 'Select Date' })
          }
          yearDropdownItemNumber={10}
          scrollableYearDropdown={true}
          showYearDropdown
          onChangeRaw={handleDateChangeRaw}
        />
        {showCalendarIcon && (
          <img
            src={calendarIcon}
            alt="Calendar icon"
            className="calendar-icon"
          />
        )}
        {error && (
          <span className="error-message">{getError()}</span>
        )}
      </div>
    </div>
  );
}

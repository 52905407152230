import { UseFormSetError } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorResponseDto } from '../api/api';
import i18n from '../i18n';

const defaultErrorHandler = (errorResponse: ErrorResponseDto) => {
    const messageError = errorResponse?.message;

    if (messageError) {
        toast.error(i18n.t(`errors:${messageError}`));
    } else {
        toast.error('Oops! Something went wrong.');
    }
};

const formErrorHandler = <T extends object>(
    formErrors: ErrorResponseDto | null,
    setError: UseFormSetError<any>,
    fields: T
): void => {
    if (!formErrors || formErrors.message !== 'ValidationErrors') {
        return;
    }

    formErrors.errors?.forEach((x) => {
        let field = Object.keys(fields).find(
            (f) => f.toLowerCase() === x.field?.toLowerCase()
        );

        if (x.field?.includes('.')) {
            const splitted = x.field.split('.');

            field = Object.keys(fields).find(
                (f) => f.toLowerCase() === splitted[1]?.toLowerCase()
            );
        }

        if (field) {
            setError(field, {
                type: 'manual',
                message:
                    i18n.t(`errors:form.${x.error}`, {
                        field: i18n.t(`formFields:${x.field}`),
                        extra: x.extra,
                    }) ?? '',
            });
        }
    });
};

export { defaultErrorHandler, formErrorHandler };

import React from 'react'
import ReactModal from "react-modal"
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import styles from './LanguageModal.module.scss'
import { availableLanguages } from '../../i18n'
import { setLanguage } from '../../redux/modules/appReducer'

interface Props extends ReactModal.Props {
    onCancel: () => void
}

const LanguageModal = (props: Props) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    return (
        <ReactModal
            {...props}
            overlayClassName="app-modal__overlay"
            className="app-modal__content"
            onRequestClose={props.onCancel}
            ariaHideApp={false}
        >
            <div className={styles.modalContainer}>
                <div className={styles.contentWrapper}>
                    <h3>{t('changeLanguage', { defaultValue: 'Change language' })}</h3>

                    <div className={styles.languages}>
                        {availableLanguages.map(x => (
                            <button
                                key={x.value}
                                type="button"
                                onClick={() => {
                                    i18n.changeLanguage(x.value)
                                    dispatch(setLanguage(x.value))
                                }}
                                className={classNames({
                                    [styles.active]: i18n.language === x.value
                                })}
                            >
                                {x.title}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        </ReactModal>
    )
}

export default LanguageModal
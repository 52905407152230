import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { PetPassportResponse } from '../../api';
import adminPetsService from '../../services/adminPetsService';

interface Props {
  petId: number;
  isVisible: boolean;
  onClose: () => void;
}

const PetPassportsModal = (props: Props) => {
  const [passports, setPassports] = useState<PetPassportResponse[] | null>(
    null
  );

  useEffect(() => {
    adminPetsService
      .getPassports({
        id: props.petId,
      })
      .then((res) => {
        setPassports(res);
      });
  }, []);

  return (
    <ReactModal
      isOpen={props.isVisible}
      className="app-modal__content"
      overlayClassName="app-modal__overlay"
      onRequestClose={() => props.onClose()}
    >
      <div style={{ padding: 32 }}>
        <h2 style={{ marginTop: 0, marginBottom: 16 }}>
          All uploaded passports
        </h2>

        {passports?.map((x) => (
          <div key={x.id}>
            {' '}
            <a href={x.url as string} target="_blank" rel="noreferrer">
              {moment.utc(x.uploaded).local().format('LLL')}
            </a>
          </div>
        ))}

        {passports?.length === 0 && <p>No uploaded passports.</p>}
      </div>
    </ReactModal>
  );
};

export default PetPassportsModal;

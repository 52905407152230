import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { AppLanguage } from '../redux/modules/appReducer';
import en from './locales/en';
import es from './locales/es';
import el from './locales/el';
import it from './locales/it';
import nl from './locales/nl';
import de from './locales/de';
import tr from './locales/tr';
import fr from './locales/fr';

export const availableLanguages = [
    {
        value: AppLanguage.en,
        title: 'English',
    },
    {
        value: AppLanguage.es,
        title: 'Spanish',
    },
    {
        value: AppLanguage.el,
        title: 'Greek',
    },
    {
        value: AppLanguage.it,
        title: 'Italian',
    },
    {
        value: AppLanguage.nl,
        title: 'Dutch',
    },
    {
        value: AppLanguage.de,
        title: 'German',
    },
    {
        value: AppLanguage.tr,
        title: 'Turkish',
    },
    {
        value: AppLanguage.fr,
        title: 'French',
    },
];

const languageDetector = {
    type: 'languageDetector',
    async: true,
    detect: (cb: any) => cb('en'),
    init: () => {},
    cacheUserLanguage: () => {},
};

i18n.use(initReactI18next)
    // @ts-ignore
    .use(languageDetector)
    .init({
        resources: {
            en,
            es,
            el,
            it,
            nl,
            de,
            tr,
            fr,
        },
        lng: 'en',
        fallbackLng: 'en',
        defaultNS: 'common',
        debug: process.env.NODE_ENV === 'development',
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;

import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import useYupValidationResolver from '../../../../utils/use-yup-validation-resolver';
import '../../auth.scss';

interface Props {
  onNextStep: (value: string) => void;
  pending: boolean;
}

const Email = (props: Props) => {
  const { t } = useTranslation()
  const validationSchema: SchemaOf<{ email: string }> = Yup.object({
    email: Yup.string()
      .email()
      .required(),
  });

  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<{ email: string }>({
    resolver,
  });

  useEffect(() => {
    setFocus('email');
  }, [setFocus]);

  const onSubmit = () => {
    props.onNextStep(getValues('email'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="reset-password-step-title">
        {t('enterEmailToReceiveCode')}
      </div>
      <Input
        {...register('email')}
        placeholder={t('formFields:email')}
        inputStyle="background-dark"
        inputSize="large"
        error={errors.email}
        disabled={props.pending}
      />
      <Button
        title={t('next')}
        type="submit"
        color="secondary"
        loading={props.pending}
        className={'submit-button'}
      />
    </form>
  );
};

export default Email;

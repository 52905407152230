import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import RichTextEditor from '../../../components/RichTextEditor';
import adminFaqService from '../../../services/adminFaqService';
import { formErrorHandler } from '../../../utils/error-handler';
import useYupValidationResolver from '../../../utils/use-yup-validation-resolver';
import { defaultLanguage, formFieldTranslationDefaultValues, formFieldTranslationPlaceholder, formFieldTranslationYup, formFieldTranslations } from '../../../utils/formTranslation';
import AdminLanguageSwitcher from '../../../components/AdminLanguageSwitcher';

type FaqFormValues = {
  id?: number;
  question: { [key: string]: string }
  answer: { [key: string]: string }
};

interface State {
  saving: boolean;
  loading: boolean;
}

const AdminSaveFaqPage = () => {
  const { t } = useTranslation()
  const [state, setState] = useState<State>({
    saving: false,
    loading: false,
  });
  const [language, setLanguage] = useState(defaultLanguage)

  const params = useParams<{ id: string }>();
  const history = useHistory();

  const validationSchema = Yup.object({
    id: Yup.number(),
    question: formFieldTranslationYup(),
    answer: formFieldTranslationYup()
  });

  const resolver = useYupValidationResolver(validationSchema);

  const {
    handleSubmit,
    formState: { errors },
    setError,
    getValues,
    setValue,
    control,
  } = useForm<FaqFormValues>({
    resolver,
    defaultValues: {
      question: formFieldTranslationDefaultValues,
      answer: formFieldTranslationDefaultValues
    }
  });

  useEffect(() => {
    if (params.id) {
      setState({
        ...state,
        loading: true,
      });

      adminFaqService.getById(parseInt(params.id, 10)).then(
        (res) => {
          setValue('id', res.id || 0);
          setValue('question', formFieldTranslations(res.questionTx || []));
          setValue('answer', formFieldTranslations(res.answerTx || []));

          setState({
            ...state,
            loading: false,
          });
        },
        () => {
          history.push('/admin/faq');
        }
      );
    }
  }, [params.id]);

  const saveChanges = () => {
    const apiCall = params.id ? adminFaqService.put : adminFaqService.post;

    setState({
      ...state,
      saving: true,
    });

    apiCall({
      faqRequestDto: {
        id: getValues('id'),
        questionTx: getValues('question'),
        answerTx: getValues('answer'),
      },
    }).then(
      () => {
        toast.success(t('messages.successFullyUpdatedFaq', { defaultValue: 'Successfully updated FAQ' }));
        history.push('/admin/faq');
      },
      (err) => {
        formErrorHandler<FaqFormValues>(err, setError, getValues());
        setState({
          ...state,
          saving: false,
        });
      }
    );
  };

  return (
    <div className="app-page-wrapper">
      <div className="page-card">
        <form onSubmit={handleSubmit(saveChanges)}>
          <div className="page-header">
            <h3>{params.id ? t('editFaq', { defaultValue: 'Edit FAQ' }) : t('newFaq', { defaultValue: 'New FAQ' })}</h3>

            <div className="page-header__actions">
              <Button
                onClick={() => { }}
                title={t('save')}
                size="small"
                loading={state.saving}
                type="submit"
              />
            </div>
          </div>
          <div className="page-content">
            {state.loading && <div className="spinner" />}
            {!state.loading && (
              <>
                <div className="mb-4">
                  <AdminLanguageSwitcher
                    onChange={setLanguage}
                    current={language}
                    errors={errors}
                  />
                </div>

                <Controller
                  name={`question.${language}`}
                  key={`question.${language}`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      type="text"
                      inputSize="large"
                      error={errors.question ? errors.question?.[language] : undefined}
                      placeholder={formFieldTranslationPlaceholder(getValues(), `question`)}
                      {...field}
                    />
                  )}
                />

                <div className="mt-4">
                  <Controller<FaqFormValues>
                    name={`answer.${language}`}
                    key={`answer.${language}`}
                    control={control}
                    render={({ field }) => (
                      <RichTextEditor
                        field={field}
                        error={errors.answer ? errors.answer?.[language] : undefined}
                      />
                    )}
                  />
                </div>
              </>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default AdminSaveFaqPage;

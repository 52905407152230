import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum AppLanguage {
    en = 'en',
    es = 'es',
    el = 'el',
    it = 'it',
    nl = 'nl',
    de = 'de',
    tr = 'tr',
    fr = 'fr',
}

export type AppReducerState = Readonly<{
    language: AppLanguage;
    hasUnreadMessage: boolean;
}>;

export const initialState: AppReducerState = {
    language: AppLanguage.en,
    hasUnreadMessage: false,
};

/* eslint no-param-reassign: "off" */
const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLanguage(state, action: PayloadAction<AppLanguage>) {
            state.language = action.payload;
        },
        setHasUnreadMessage(state, action: PayloadAction<boolean>) {
            state.hasUnreadMessage = action.payload;
        },
    },
});

const { actions, reducer } = appSlice;
export const { setLanguage, setHasUnreadMessage } = actions;
export default reducer;

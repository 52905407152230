import _ from 'lodash';
import * as yup from 'yup';
import { TranslationField } from '../api';

export const availableLanguages = ['en', 'es', 'el', 'it', 'nl', 'de', 'tr'];
export const defaultLanguage = 'en';

export const formFieldTranslationYup = (
    defaultLanguageValidation?: (s: yup.StringSchema) => yup.StringSchema,
    allLanguageValidation?: (s: yup.StringSchema) => yup.StringSchema
) => {
    const baseValidation = yup.string();

    const defaultValidation = defaultLanguageValidation
        ? defaultLanguageValidation(baseValidation)
        : baseValidation;

    const allValidation = allLanguageValidation
        ? allLanguageValidation(baseValidation)
        : baseValidation;

    return yup
        .object()
        .required()
        .shape(
            JSON.parse(JSON.stringify(availableLanguages))
                .sort()
                .reduce(
                    (ac: string[], a: string) => ({
                        ...ac,
                        [a]:
                            a === defaultLanguage
                                ? defaultValidation.nullable()
                                : allValidation.nullable(),
                    }),
                    {}
                )
        );
};

export const formFieldTranslationDefaultValues = availableLanguages.reduce(
    (ac, a) => ({
        ...ac,
        [a]: undefined,
    }),
    {}
);

export const formFieldTranslationPlaceholder = (values: any, field: string) => {
    return _.get(values, [...field.split('.'), defaultLanguage]);
};

export const formFieldTranslations = (values: TranslationField[]) =>
    Object.fromEntries(values.map((e) => [e.language, e.value]));

export const toTranslationFieldArray = (object: { [key: string]: string }) => {
    const result: TranslationField[] = [];

    /* eslint no-restricted-syntax: off */
    for (const key in object) {
        if (object[key]) {
            result.push({
                language: key,
                value: object[key],
            });
        }
    }

    return result;
};

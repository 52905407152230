import React from 'react'
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

interface Props {
    error?: FieldError;
    fieldName: string
}

const FormError = (props: Props) => {
    const { t } = useTranslation()
    const { error } = props

    const getError = () => {
        if (!error) {
            return null
        }

        const msg = error.message as unknown as { key: string; extra: any };

        if (msg.key) {
            return t(`errors:form.${msg.key}`, {
                field: props.fieldName ? t(`formFields:${props.fieldName}`) : null,
                extra: msg.extra,
            });
        }

        return error.message;
    };

    if (!props.error) {
        return null;
    }

    return <>
        {getError()}
    </>
}

export default FormError
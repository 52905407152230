import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';
import { SchemaOf } from 'yup';
import { useTranslation } from 'react-i18next';
import '../../../assets/scss/onboarding-modal.scss';
import { AppState } from '../../../redux/rootReducer';
import useYupValidationResolver from '../../../utils/use-yup-validation-resolver';
import FormError from '../../FormError';

interface Props {
  onNextStep: (value: string) => void;
}

const Username = (props: Props) => {
  const { t } = useTranslation()

  const user = useSelector((state: AppState) => state.auth.user);

  const validationSchema: SchemaOf<{ name: string }> = Yup.object({
    name: Yup.string().required(),
  });

  const resolver = useYupValidationResolver(validationSchema);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    setFocus,
    formState: { errors },
  } = useForm<{ name: string }>({
    resolver,
  });

  useEffect(() => {
    if (user != null) {
      setValue('name', user.name || '');
      setFocus('name');
    }
  }, [setFocus]);

  const onSubmit = () => {
    props.onNextStep(getValues('name'));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <span className="description-text-onboarding">
        {t('userOnboarding.firstNameText')}
      </span>
      <span className="step-text-onboarding">{t('step').toUpperCase()} 1/5</span>
      <div className="d-flex">
        <input
          {...register('name')}
          className="modal-input-onboarding"
          placeholder={t('formFields:name')}
          defaultValue={getValues('name')}
        />
        <button type="submit" className="modal-input-button-onboarding">
          {t('next')}
        </button>
      </div>

      {errors.name?.message && (
        <span className="error-message-text">
          <FormError
            fieldName="name"
            error={errors.name}
          />
        </span>
      )}
    </form>
  );
};

export default Username;

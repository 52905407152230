import * as yup from 'yup';

const initTranslations = () => {
    yup.setLocale({
        mixed: {
            required: { key: 'Required' },
        },
        string: {
            email: () => ({ key: 'NotValidEmailAddress' }),
            min: ({ min }: any) => ({
                key: 'MinLength',
                extra: { MinLength: min },
            }),
            max: ({ max }: any) => ({
                key: 'MaxLength',
                extra: { MaxLength: max },
            }),
        },
    });
};

export default { initTranslations };

import { UserRoleEnum } from '../api/api';
import faqIcon from '../assets/images/menu-icons/faq.png';
import liabilityIcon from '../assets/images/menu-icons/liability.png';
import petIcon from '../assets/images/menu-icons/pet.png';
import profileIcon from '../assets/images/menu-icons/profile.png';
import summaryIcon from '../assets/images/menu-icons/summary.png';
import i18n from '../i18n';

interface MenuItem {
    to: string;
    image: any;
    title: string;
    exact?: boolean;
}

const getMenuForRole = (role: UserRoleEnum, lng: string) => {
    const userMenu: MenuItem[] = [
        {
            to: '/',
            image: summaryIcon,
            title: i18n.t('menu.summary', { lng }),
            exact: true,
        },
        {
            to: '/user-profile',
            image: profileIcon,
            title: i18n.t('menu.userProfile', { lng }),
        },
        {
            to: '/pets',
            image: petIcon,
            title: i18n.t('menu.pets', { lng }),
        },
        {
            to: '/forms',
            image: liabilityIcon,
            title: i18n.t('menu.forms', { lng }),
        },
    ];

    const adminMenu: MenuItem[] = [
        {
            to: '/admin/dashboard',
            image: summaryIcon,
            title: 'Dashboard',
        },
        {
            to: '/admin/pets',
            image: petIcon,
            title: 'Pets',
        },
        {
            to: '/admin/users',
            image: profileIcon,
            title: 'Users',
        },
        {
            to: '/admin/forms',
            image: liabilityIcon,
            title: 'Forms',
        },
        {
            to: '/admin/faq',
            image: faqIcon,
            title: 'FAQ',
        },
    ];

    switch (role) {
        case UserRoleEnum.User:
            return userMenu;
        case UserRoleEnum.SystemAdministrator:
            return adminMenu;
        default:
            return [];
    }
};

export default { getMenuForRole };

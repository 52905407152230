import common from './common.json';
import errors from './errors.json';
import formFields from './formFields.json';
import validations from './validations.json';

export default {
    common,
    errors,
    formFields,
    validations,
};

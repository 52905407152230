import classNames from 'classnames'
import React from 'react'
import { FieldErrors } from 'react-hook-form'
import styles from './AdminLanguageSwitcher.module.scss'
import { availableLanguages } from '../../utils/formTranslation'

function objectDeepKeys(obj: any): string[] {
    return Object.keys(obj).filter(key => obj[key] instanceof Object).map(key => objectDeepKeys(obj[key]).map((k: string) => k)).reduce((x, y) => x.concat(y), Object.keys(obj))
}

interface Props {
    onChange: (language: string) => void
    current: string
    errors?: FieldErrors
}
const AdminLanguageSwitcher = (props: Props) => {
    const hasErrors = (lng: string) => {
        if (objectDeepKeys(props.errors).includes(lng)) {
            return true;
        }

        return false;
    }

    return (
        <div className={styles.root}>
            {availableLanguages.map(lng => (
                <button
                    key={lng}
                    type="button"
                    onClick={() => props.onChange(lng)}
                    className={classNames({
                        [styles.current]: props.current === lng,
                        [styles.error]: hasErrors(lng)
                    })}
                >
                    {lng}
                </button>
            ))}
        </div>
    )
}

export default AdminLanguageSwitcher
import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './modules/authReducer';
import appReducer from './modules/appReducer';

const rootReducer = combineReducers({
    auth: authReducer,
    app: appReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export default rootReducer;

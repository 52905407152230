export interface SpeciesOption {
    label: string;
    value: string;
}

const json = ['Cat', 'Dog'];

const speciesData: SpeciesOption[] = json.map((x) => ({
    value: x,
    label: x,
}));

export default speciesData;
